import Constants from 'Constants'
import { EventBus } from 'EventBus'
import Common from '@/assets/js/common.js'

const PaxMixin = {
    methods: {
        getStallions: async function() {
            const url = Constants.PAX_STALLIONS + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("PaxMixin::getStallions", url)
			.catch(error => {
				console.error("PaxMixin::getStallions => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getMares: async function() {
            const url = Constants.PAX_MARES + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("PaxMixin::getMares", url)
			.catch(error => {
				console.error("PaxMixin::getMares => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getCriteres: async function(lang) {
            const url = Constants.PAX_CRITERES + "?licence_key="+Constants.USER_LICENCE_KEY + "&lang=" + lang

			const result = await this.$request.request_get_api("PaxMixin::getCriteres", url)
			.catch(error => {
				console.error("PaxMixin::getCriteres => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },
		

		paxCalculCompatiblite: async function(stallions, criteres) {
			let recap = {}

			let pourcentage_correction = 1-(2*6681/100000)

			for (let index in criteres) {
				if(criteres[index].slider) {
					let valeur_mare = parseFloat(criteres[index].slider)
					const criteres_max = criteres[index].criteres_max / 100
					const criteres_min = criteres[index].criteres_min / 100

					for(let index_stallion in stallions) {
						if(!stallions[index_stallion]) {
							continue;
						}
						let valeur_stallion = stallions[index_stallion].horse_criteres[criteres[index].criteres_id]

						let moyenne = (valeur_stallion + valeur_mare) / 2
						let ecart_maxi = (criteres_max - moyenne)
						let ecart_mini = (criteres_min - moyenne)
						let p1 = null
						let p2 = null

						if(criteres_max == 4) {
							p1 = 100
						}
						else {
							p1 = (this.getProba(ecart_maxi) - 6.6810) / pourcentage_correction
							if(p1 > 100) {
								p1 = 100
							}
						}

						if(criteres_min == 1) {
							p2 = 0
						}
						else {
							p2 = (this.getProba(ecart_mini) - 6.6810) / pourcentage_correction
							if(p2 < 0) {
								p2 = 0
							}
						}

						let p = Math.abs(p1 - p2)

						if(!recap[stallions[index_stallion].horse_id]) {
							recap[stallions[index_stallion].horse_id] = {}
						}
						recap[stallions[index_stallion].horse_id][criteres[index].criteres_id] = Math.round(p)
					}
				}
			}

			return recap
		},
		getProba: function (ecart) {
			let tab_proba = {
				"-4": 0,
				"-3.75": 0,
				"-3.5": 0,
				"-3.25": 0,
				"-3": 0.1350,
				"-2.75": 0.2980,
				"-2.5": 0.6210,
				"-2.25": 1.2220,
				"-2": 2.2750,
				"-1.75": 4.0060,
				"-1.5": 6.6810,
				"-1.25": 10.5650,
				"-1": 15.8660,
				"-0.75": 22.6630,
				"-0.5": 30.8540,
				"-0.25": 40.1290,
				"0": 50.0000,
				"0.25": 59.8710,
				"0.5": 69.1460,
				"0.75": 77.3370,
				"1": 84.1340,
				"1.25": 89.4350,
				"1.5": 93.3190,
				"1.75": 95.9940,
				"2": 97.7250,
				"2.25": 98.7780,
				"2.5": 99.3790,
				"2.75": 99.7020,
				"3":99.8650,
				"3.25": 100,
				"3.5": 100,
				"3.75": 100,
				"4": 100,
			}
			return tab_proba[ecart]
		},

		saveCaracterisationByHorse: async function(horse_id, criteres_id, criteres_value) {
            const url =  this.constructRoute(Constants.PAX_CARACTERISATION_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const params = {criteres: [{criteres_id, criteres_value}]}

            const response = await this.$request.request_post_api("PaxMixin::saveCaracterisationByHorse", url, params, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		},

		getCaracterisationByHorse: async function(horse_id) {
            const url =  this.constructRoute(Constants.PAX_CARACTERISATION_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("PaxMixin::getCaracterisationByHorse", url)
			.catch(error => {
				console.error("PaxMixin::getCaracterisationByHorse => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		saveMarePanier: async function(horse_id, stallions_ids) {
            const url =  this.constructRoute(Constants.PAX_PANIER_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_post_api("PaxMixin::saveMarePanier", url, {stallions_ids}, false)
            .catch(e => {
                return false
            })

            if(response.code_retour === 0){
                return true
            }
		},

		getMarePanier: async function(horse_id) {
            const url =  this.constructRoute(Constants.PAX_PANIER_HORSE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("PaxMixin::getMarePanier", url)
			.catch(error => {
				console.error("PaxMixin::getMarePanier => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		getInfosPaxGrahique: async function(lang, mare_id, stallion_id) {

            const url =  this.constructRoute(Constants.PAX_GRAPHIQUE_INFOS, { mare_id, stallion_id }) +'?licence_key='+Constants.USER_LICENCE_KEY  + "&lang=" + lang

			const result = await this.$request.request_get_api("PaxMixin::getInfosPaxGrahique", url)
			.catch(error => {
				console.error("PaxMixin::getInfosPaxGrahique => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		getPaxColor: async function(recap, critere) {
			const stallion_notation = {}

			for(let stallion in recap) {
				let bullet_points = 0
				
				for (let index = 0; index < critere.length; index++) {
					const element = critere[index];

					if(parseInt(recap[stallion][element.criteres_id]) < element.criteres_red) {
						bullet_points += 1
					}
					else if(parseInt(recap[stallion][element.criteres_id]) >= element.criteres_green) {
						bullet_points += 3
					}
					else if(parseInt(recap[stallion][element.criteres_id]) >= element.criteres_red && parseInt(recap[stallion][element.criteres_id]) < element.criteres_green) {
						bullet_points += 2
					}
				}

				stallion_notation[stallion] = bullet_points
			}

			return stallion_notation
		}
    }
}

export default PaxMixin