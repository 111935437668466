<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<div v-if="criteres_local.length > 2 && Object.keys(recap).length > 0" class="row">
				<div class="d-none d-lg-block d-xl-block">
					<button href="#" @click="goBack()" class="btn btn-primary btn-prev mb-2"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /> {{ $t('pax.retour') }}</button>
				</div>
				<div class="col mt-lg-4">
					<div class="row no-gutters align-items-center mb-3">
						<div class="col col-lg-12">
							<a href="#" @click="goBack()" class="btn btn-primary btn-retour mb-3 d-lg-none"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /> {{ $t('pax.retour') }}</a>
							<div class="row ml-1">
								<h3 class="mb-0 mb-lg-3">4. {{ $t('pax.comparaison') }}</h3>
								<div v-if="pax_express" class="col-auto">
									<div class="input-group stallion_search">
									    <button class="input-group-text px-3" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row no-gutters align-items-center mb-3">
						<div class="col-2 mr-auto"></div>
						<div :class="'col-'+col_stallion+' text-center'" v-for="stallion in stallions_local" :key="stallion.horse_id">
							<span class="stallionTabName" @click.prevent="displayDetailsCroisement(stallion.horse_id, stallion.horse_nom, stallion.horse_sire)">{{ stallion.horse_nom }}</span>
						</div>
					</div>
					<div class="tabLine" v-for="critere in criteres_local" :key="critere.criteres_id">
						<div class="row no-gutters align-items-center">
							<div class="col-2 mr-auto">
								{{ critere.critereslang_label }}
							</div>
							<div :class="'col-'+col_stallion+' text-center'" v-for="stallion in stallions_local" :key="stallion.horse_id">
								<span class="bullet red" v-if="parseInt(recap[stallion.horse_id][critere.criteres_id]) < critere.criteres_red" @click.prevent="displayDetailsCroisement(stallion.horse_id, stallion.horse_nom, stallion.horse_sire)">
								</span>
								<span class="bullet green" v-else-if="parseInt(recap[stallion.horse_id][critere.criteres_id]) >= critere.criteres_green" @click.prevent="displayDetailsCroisement(stallion.horse_id, stallion.horse_nom, stallion.horse_sire)">
								</span>
								<span class="bullet orange" v-else-if="parseInt(recap[stallion.horse_id][critere.criteres_id]) >= critere.criteres_red && parseInt(recap[stallion.horse_id][critere.criteres_id]) < critere.criteres_green" @click.prevent="displayDetailsCroisement(stallion.horse_id, stallion.horse_nom, stallion.horse_sire)">
								</span>
								<span class="bullet grey" v-else @click.prevent="displayDetailsCroisement(stallion.horse_id, stallion.horse_nom, stallion.horse_sire)"></span>
							</div>
						</div>
					</div>
					<div class="row no-gutters align-items-center my-3">
						<div class="col-2 mr-auto">
							
						</div>
				
						<div :class="'col-'+col_stallion+' text-center px-1'" v-for="stallion in stallions_local" :key="stallion.horse_id">
							<div v-if="isLg || isXl" class="row no-gutters tools mt-4 modal-pax justify-content-center">
								<template v-if="pax_express">
									<div class="col-12 mb-2 px-1">
										<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + stallion.horse_nom + '&horse_sire=' + stallion.horse_sire " target="_blank"><span><i>{{stallion.horse_nom}}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
									</div>
									<div class="col-12 px-1">
										<a class="modal-btn" target="_blank" href="https://clients.gfeweb.com/"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /> <b>{{ $t('pax.reserver_en_ligne') }}</b></span></a>
									</div>
								</template>
								<template v-else>
									<div class="col-12 py-1 mt-md-2 mt-xxl-0">
										<router-link :to="{ name: 'ContractNew', params: {stallion_id: stallion.horse_id, mare_id: mare.horse_id}}" target="_blank" class="modal-btn"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /><b>{{ $t('pax.reserver_en_ligne') }}</b></span></router-link>
									</div>
									<div class="col-12 py-1">
										<a class="modal-btn" href="#" @click.prevent="goToPaxGraphique(stallion.horse_id)"><span><font-awesome-icon :icon="['far', 'chart-area']" /> <b>{{ $t('pax.go_to_pax_graphique') }}</b></span></a>
									</div>
									<div class="col-12 py-1">
										<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + stallion.horse_nom + '&horse_sire=' + stallion.horse_sire " target="_blank"><span><i>{{stallion.horse_nom}}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
									</div>
								</template>
							</div>

							<button v-if="!isLg && !isXl" class="btn btn-dark rounded-pill w-100 d-block" @click.prevent="displayDetailsCroisement(stallion.horse_id, stallion.horse_nom, stallion.horse_sire)">{{ $t('pax.choisir') }}</button>
						</div>
					</div>
					<div id="newAccount" v-if="pax_express && !isLg && !isXl">
						<h3>{{ $t('pax.creer_compte_pour') }} :</h3>
						<ul>
							<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.obtenier_analyse_approfondie') }}</li>
							<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.comparer_dix_etalons') }}</li>
							<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.voir_recommandation_pax') }}</li>
							<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.save_votre_jument') }}</li>
						</ul>
						<p></p>
						<a href="https://clients.gfeweb.com/" class="btn btn-white">{{ $t('pax.se_connecter') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></a>
					</div>
				</div>
				<div id="newAccount" class="col-lg-3 news_accountLG" v-if="pax_express && (isLg || isXl)">
					<h3 class="mt-lg-4 mb-lg-3">{{ $t('pax.creer_compte_pour') }} :</h3>
					<ul>
						<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.obtenier_analyse_approfondie') }}</li>
						<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.comparer_dix_etalons') }}</li>
						<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.voir_recommandation_pax') }}</li>
						<li><font-awesome-icon :icon="['fas', 'caret-right']"/> {{ $t('pax.save_votre_jument') }}</li>
					</ul>
					<a href="https://clients.gfeweb.com/" class="btn btn-white">{{ $t('pax.se_connecter') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></a>
				</div>

				<b-modal dialog-class="modal-pax" ref="modalChoix" hide-footer>
					<template v-slot:modal-title class="d-flex">
						{{ $t('pax.detail_croisement') }}
						<button class="input-group-text px-3 modal-header-btn" v-if="pax_express" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
						<template v-if="!pax_express">
							<span class="elipsis">{{ stallion_nom }} X {{ mare.horse_nom }}</span>
						</template>
					</template>

					<template v-if="stallion_choose != null">
						<div class="tabLine" v-for="critere in criteres_local" :key="critere.criteres_id">
							<div class="row no-gutters align-items-center" >
								<div class="col-6">
									{{ critere.critereslang_label }}
								</div>
								<div class="col text-center">
									<span class="bullet red" v-if="parseInt(recap[stallion_choose][critere.criteres_id]) < critere.criteres_red">
									</span>
									<span class="bullet green" v-else-if="parseInt(recap[stallion_choose][critere.criteres_id]) >= critere.criteres_green">
									</span>
									<span class="bullet orange" v-else-if="parseInt(recap[stallion_choose][critere.criteres_id]) >= critere.criteres_red && parseInt(recap[stallion_choose][critere.criteres_id]) < critere.criteres_green">
									</span>
									<span class="bullet grey" v-else></span>
								</div>
							</div>
						</div>

						<div class="row no-gutters tools mt-4">
							<template v-if="pax_express">
								<div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 px-1">
									<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + stallion_nom + '&horse_sire=' + stallion_sire " target="_blank"><span><i>{{ stallion_nom }}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
								</div>
								<div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  mt-3 mt-md-0 px-1">
									<a class="modal-btn" href="https://clients.gfeweb.com/" target="_blank"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /> <b>{{ $t('pax.reserver_en_ligne') }}</b></span></a>
								</div>
							</template>
							<template v-else>
								<div class="col-xxl-4 col-xl-6 col-lg-4 col-md-4 col-12 px-1">
									<router-link :to="{ name: 'ContractNew', params: {stallion_id: stallion_choose.horse_id, mare_id: mare.horse_id}}" target="_blank" class="modal-btn"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /><b>{{ $t('pax.reserver_en_ligne') }}</b></span></router-link>
								</div>
								<div class="col-xxl-4 col-xl-6 col-lg-4 col-md-4 col-12 px-1 mt-3 mt-md-0">
									<a class="modal-btn" href="#" @click.prevent="goToPaxGraphique(stallion_choose)"><span><font-awesome-icon :icon="['far', 'chart-area']" /> <b>{{ $t('pax.go_to_pax_graphique') }}</b></span></a>
								</div>
								<div class="col-xxl-4 col-xl-6 col-lg-4 col-md-4 col-12 px-1 mt-3 mt-md-0">
									<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + stallion_nom + '&horse_sire=' + stallion_sire " target="_blank"><span><i>{{ stallion_nom }}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
								</div>
							</template>
						</div>
					</template>
				</b-modal>


			</div>
			<template v-else> 
				<p>{{ $t('pax.completer_criteres_voir_compatibilite') }}</p>
			</template>

			<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
				<template v-slot:modal-title>
					<template v-if="pax_express">{{ $t('pax.pax_express') }}</template>
					<template v-else>{{ $t('pax.pax') }}</template>
				</template>

				<div v-html="text_intro"></div>
			</b-modal>
		</div>
	</div>
</template>
<script type="text/javascript">
	import Vue from 'vue'
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import Window from '@/mixins/Window.js'
	import _cloneDeep from 'lodash/cloneDeep';

	export default {
		name: "ResultCompatibiliteV",
		props: {
			criteres: {
				type: Array,
				default: () => ( [] )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			pax_express: {
				type: Boolean,
				default: () => false
			},
			mare: {
				type: Object,
				default: () => ( null )
			},
			show: {
				type: Boolean,
				default: () => false
			}
		},
		mixins: [Navigation, PaxMixin, Tools, Window],
		data () {
			return {
				addOrEditReady: false,
				stallions_local: [],
				criteres_local: [],
				lang: 'fr',
				stallion_choose: null,
				stallion_nom: null,
				stallion_sire: null,
				recap: {},
				text_intro: ''
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.text_intro = this.getConfig('translations')['pax_introduction'][Vue.i18n.locale()]
				if(this.pax_express) {
					this.text_intro = this.getConfig('translations')['pax_express'][Vue.i18n.locale()]
				}
				this.addOrEditReady = false
				this.stallions_local = _cloneDeep(this.stallions.filter(st => st))
				this.criteres_local = _cloneDeep(this.criteres)

				this.calculCompatibilite()

				this.lang = this.getConfig('lang')

				this.addOrEditReady = true
			},

			async calculCompatibilite() {
				this.recap = await this.paxCalculCompatiblite(this.stallions_local, this.criteres_local)
			},

			async displayDetailsCroisement(stallion_id, stallion_nom, stallion_sire) {
				this.stallion_choose = stallion_id
				this.stallion_nom = stallion_nom
				this.stallion_sire = stallion_sire
				this.$refs["modalChoix"].show()
			},

			async goBack() {
				this.$emit("update:step_label", "mare_list")
			},

			goToPaxGraphique(stallion_id) {
				this.$refs["modalChoix"].hide()
				this.$emit("update:stallion_id", stallion_id)
				this.$emit('update:step_label', "pax_graphique")
			},

			openIntroduction() {
				this.$refs["modalChoix"].hide()
				this.$refs["modal"].show()
			}
			
		},
		computed: {
			col_stallion() {
				const nb_stallions = this.stallions.length
				if(nb_stallions == 1 && this.pax_express) return 10
				if(nb_stallions == 2 && this.pax_express) return 5
				if(nb_stallions == 3 && this.pax_express) return 2
				if(nb_stallions == 1 && !this.pax_express) return 9
				if(nb_stallions == 2 && !this.pax_express) return 5
				if(nb_stallions == 3 && !this.pax_express) return 3
				return 3
			},
			col_empty_stallion() {
				const nb_stallions = this.stallions.length
				if(nb_stallions == 1 && this.pax_express) return 2
				return 0
			}
		},
		watch: {
			stallions(val) {
				this.stallions_local = _cloneDeep(val.filter(st => st))
				this.calculCompatibilite()
			},
			'criteres': {
				deep: true,
				handler(val){
					this.criteres_local = _cloneDeep(val)
					this.calculCompatibilite()
				}
			},
			show(val) {
				if(val) {
					window.scrollTo({ top: 250, behavior: 'smooth' });
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
